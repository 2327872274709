<template>
<div class="p-5">
    <div class=" mb-5">
                <h1 class="text-5xl  mb-5">Privacy Policy</h1>
    <p>Thanks for visiting {{ url }}!! Please take a moment to read our Privacy Policy before you continue using our website. This Privacy Policy describes our policies and procedures on the collection, use, disclosure, and sharing of your personal information when you use this website.</p>   
    </div>
 
    <div class="mb-5">
          <h2 class="text-3xl mb-5">SECTION 1 - COLLECTION OF PERSONAL INFORMATION</h2>
    <p class="mb-3">Generally, we collect your personal information on a voluntary basis. However, if you decline to provide certain personal information that is marked mandatory, you may not be able to access certain Services or we may be unable to fully respond to your inquiry. </p>   
    <p>Information we collect directly. We collect information that you provide to us, such as:
        <ul class="list-disc list-inside">
            <li>when you provide contact information, and send other personal information to us;</li>
            <li> when you complete or submit a form throughs {{ url }}</li>
        </ul>
   </p>
    </div>
    
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 2 - HOW WE USE YOUR PERSONAL INFORMATION</h2>
        <p class="mb-3">While the purposes for which we may process personal information will vary depending upon the circumstances, in general we use personal informationfor the purposes set forth below. Where GDPR or other relevant laws apply, we have set forth the legal bases for such processing in parenthesis</p>
        <ul class="list-decimal list-inside">
            <li class="mb-2">Providing support and services: including, for example, to provide products and services you request (and send related information), operates {{ url }}; to communicate with you about your access to and use of our services; to respond to your inquiries; to provide troubleshooting, fulfil your requests and provide technical support; and for other customer service and support purposes.</li>
            <li class="mb-2">Analysing and improving our business: including to better understand how users access and use {{ url }}, to evaluate and improve our services and business operations, and to develop new features, offerings, and services; to conduct surveys, and other evaluations, such as customer satisfaction surveys; and for other research and analytical purposes.</li>
            <li class="mb-2">Defending our legal rights: including to manage and respond to actual and potential legal disputes and claims, and to otherwise establish, defend or protect our rights or interests, including inthe context of anticipated or actual litigation with third parties.</li>
            <li class="mb-2">Complying with legal obligations: including to comply with the law, our legal obligations and legal process, such warrants, subpoenas, court orders, and regulatory or law enforcement requests.</li>
        </ul>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 3 - DISCLOSURE OF PERSONAL INFORMATION</h2>
        <p class="mb-3">
            We may disclose the personal information that we collect about you as set forth below or as otherwise described at the time of collection or sharing.
            <ul class="list-decimal list-inside">
                <li class="mb-2">Serviceproviders: We may disclose personal information with third-party service providers who use this information to perform services for us, such as hosting providers, auditors, advisors, consultants, and customer service and support providers.</li>
                <li class="mb-2">Third Parties:  We may employ other companies and individuals to perform functions on our behalf. Examples include fulfilling orders for products or services, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, analysing data, providing marketing assistance, processing payments, transmitting content, and providing customer service. These third-party service providers have access to personal information needed to perform their functions, but may not use it for other purposes.</li>
                <li class="mb-2">Business transfers: We may disclose or transfer personal information as part of any actual or contemplated merger, sale, and transfer of our assets, acquisition, financing or restructuring of all or part of our business, bankruptcy or similar event, including related to due diligence conducted prior to such eventwhere permitted by law.</li>
                <li class="mb-2">Legally required: We may disclose personal information if we are required todo so by law (e.g., to law enforcement, courts or others, e.g., in response to a subpoena or court order).</li>
                <li class="mb-2">Protect our rights: We may disclose personal information where we believe itnecessary to respond to claims asserted against us or, comply with legal process (e.g. warrants), enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and to protect the rights, property, or safety of us, our clients and customers or others.</li>
            </ul>
        </p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 4 - HOW WE PROTECT YOUR INFORMATION</h2>
        <p>Our security measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.</p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 5 - COOKIES</h2>
        <p class="mb-3">
            Cookies are small text files that a website transfers to your hard drive to store and sometimes collect information about your usage of websites, such as time spent on the websites, pages visited, language preferences, and other anonymous traffic data. You can control the way in which cookies are usedby altering your browser settings. You may refuse to accept cookies by activating the setting on your browser that allows you to reject cookies. However, if you select such a setting, this may affect the functioning of {{ url }}. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you access or log on to our Service.
        </p>
        <h3 class="text-2xl mb-3">We may use the following types of cookies:</h3>
        <ul class="text-base list-disc list-inside mb-2">
            <li class="mb-2">Security: These cookies allow us to secure access to your account.</li>
            <li class="mb-2">Preference: These cookies are used to store your preferences like language choice and display of search results.</li>
            <li class="mb-2">Analytics: We track traffic patterns so we can identify popular content and potential problems.</li>
            <li class="mb-2">Features: We may also use cookies to split some users into test groups to test new features.</li>
            <li class="mb-2">Advertising: We use non-identifiable information about you to show you advertising on our services and third-party sites.</li>
        </ul>
     </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 6 - THIRD PARTY ANALYTICS TOOLS</h2>
        <p class="mb-2">Our website uses automated devices and applications operated by third parties, such as Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the websites and report on activities and trends. This service may also collect information regarding the use of other websites, apps and online resources.</p>
        <p>We gather statistical information about use of the Services in order to continually improve their design and functionality, understand how they are used and assist us with resolving questions regarding them.</p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 7 - DO-NOT-TRACK SIGNALS</h2>
        <p>
            Please note that our websites do not recognize or respond to anysignal which your browser might transmit through the so-called 'Do Not Track' feature your browser might have.
        </p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 8 - SECURITY</h2>
        <p>We use technical, administrative, and physical controls in place to help protect Personal information from unauthorized access, use, and disclosure. Even so, despite our reasonable efforts, no security measure isever perfect or impenetrable. In the event that the security of your account has been compromised, please immediately notify us by visiting (“Contact Us”).
        </p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 9 - DO NOT SELL MY DATA</h2>
        <p>Except as otherwise stated in this Privacy Policy, we do not sell,trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent.</p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">SECTION 10 - DATA RETENTION</h2>
        <p>
            We will retain your personal information for the period necessary to fulfill the purposes outlined in this privacy notice unless a longer retention period is required or permitted by law.
        </p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">
            SECTION 11 - CCPA PRIVACY RIGHTS
        </h2>
        <p class="mb-2">
            Under the CCPA, among other rights, California consumers have the right to:
        </p>
        <p class="mb-2">
            Requestthat a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
        </p>
        <ul class="list-disc list-inside">
            <li class="mb-2">Request that a business deletes any personal data about the consumer that a business has collected.</li>
            <li class="mb-2">Request that a business that sells a consumer'spersonal data, not sell the consumer's personal data.</li>
        </ul>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">
            SECTION 12 - GDPR DATA PROTECTION RIGHTS
        </h2>
        <p class="mb-2">
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to thefollowing:
        </p>
        <ul class="list-decimal list-inside">
            <li class="mb-2">Right to access 
                <p class="mt-2">
                    If you can prove your identity, you have the right to obtain information about the processing of your data. Thus, you have the right to know the purposes of the processing, the categories of data concerned, the categories of recipients to whom the data are transmitted, the criteria used to determine the data retention period, and the rights that you can exercise on your data.
                </p>
            </li>
            <li class="mb-2">
                Right to rectification of your personal data
                <p class="mt-2">
                    Inaccurate or incomplete personal data may be corrected.
                </p>
            </li>
            <li class="mb-2">
                Right to erasure (or “right to be forgotten”)
                <p class="mt-2">
                    You also have the right to obtain the erasure of your personal data under the following assumptions:
                </p>
                <ul class="list-disc list-inside mt-2">
                    <li class="mb-2">Your personal data are no longer necessary for the intended purposes;</li>
                    <li class="mb-2">You withdraw your consent to the processing and there is no other legal ground for processing;</li>
                    <li class="mb-2">You have validly exercised your right of opposition;</li>
                    <li class="mb-2">Your data has been illegally processed;</li>
                    <li class="mb-2">Your data must be deleted to comply with a legal obligation</li>
                </ul>
                <p class="mt-2">
                    The deletion of data is mainly related to visibility; it is possible that the deleted data are still temporarily stored.
                </p>
            </li>
            <li class="mb-2">
                Right to limitation of processing
                <p class="mt-2">
                    In certain cases, you have the right to request the limitation of the processing of your personal data, especially in case of dispute as to the accuracy of the data, if the data are necessary in the context of legal proceedings or the time required to verify that you can validly exercise your right to erasure.
                </p>
            </li>
            <li class="mb-2">
                Right to object
                <p class="mt-2">
                    You have the right to object at any time to the processing of your personal data for direct marketing purposes. We will stop processing your personal data unless it can demonstrate that there are compelling legitimate reasons for the processing which prevail over your right to object.
                </p>
            </li>
            <li class="mb-2">
                Right to data portability
                <p class="mt-2">
                    You have the right to obtain any personal data which you have provided us in a structured, commonly used and machine-readable format. You are then free to transfer this data to a similar service provider.
                </p>
            </li>
            <li class="mb-2">
                Right to withdraw your consent
                <p class="mt-2">
                    You may withdraw your consent to the processing of your personal data at any time, for example for personalized marketing communication purposes.
                </p>
            </li>
        </ul>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">
            SECTION 13 - THIRD-PARTY WEBSITES
        </h2>
        <p>
            The Services may contain links to third-party services. When you click on those links, you will go to a third-party website where you will be subject to that service’s privacy policy or similar statement and terms of use, and we encourage you to read that policy statement. We are not responsible forthe privacy practices of other services, and we expressly disclaim any liability for their actions, including actions related to the use and disclosure of personal information by those third parties.
        </p>
    </div>
    <div class="mb-5">
        <h2 class="text-3xl mb-5">
            SECTION 14 - CHANGES TO THIS POLICY
        </h2>
        <p>
            We may amend this Policy at any time. If we make any material change in how we collect, use, disclose, or otherwise process personal information, we may not post a notice regarding such change on the Services.
        </p>
    </div>


</div>


</template>

<script>
import configSetup from '@/setup.js'

export default {
      created() {
    const { company } = configSetup();
    document.title = `${company} | Privacy Policy`
    },


    setup() {
        const {url} = configSetup()

        return {url}
    }


}
</script>
